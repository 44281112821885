<!--
 * @Author: LiZhiWei
 * @Date: 2024-07-18 14:43:05
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-08-15 11:17:54
 * @Descripttion: 
-->
<template>
  <el-tooltip :effect="props.effect" :content="props.content" :placement="props.placement">
    <template #content>
      <div class="content" :style="`max-width: ${props.width}px`">{{ props.content }}</div>
    </template>
    <template #default>
      <slot />
    </template>
  </el-tooltip>
</template>
<script setup lang="ts">
  // 定义props的类型
  interface props {
    content: string
    width: number
    effect: string
    placement: string
  }
  // 使用withDefaults来给props赋默认值
  const props = withDefaults(defineProps<props>(), {
    content: '',
    width: 200,
    effect: 'light',
    placement: 'bottom',
  })
</script>
<style></style>
